<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Service
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <is-authorized :permissions="['manage:fleets', 'update:fleets']">
        <button
          @click="addService()"
          class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
        >
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path d="M12 5v14M5 12h14" />
          </svg>
          <span>Add Service</span>
        </button>
      </is-authorized>
    </div>

    <div class="flex flex-wrap">
      <vue-good-table
        v-if="services.length > 0"
        class="w-full"
        styleClass="vgt-table striped"
        mode="remote"
        :rows="services"
        :columns="columns"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'is_active'">
            <span
              class="px-2 rounded-lg"
              :class="{
                'bg-yellow-200 text-yellow-700': props.row.is_active == false,
                'bg-green-200 text-green-700': props.row.is_active == true,
              }"
              >{{ props.row.is_active ? "Active" : "Expired" }}</span
            >
          </span>
          <span v-else-if="props.column.field == 'actions'">
            <is-authorized :permissions="['manage:fleets', 'delete:fleets']">
              <button
                type="button"
                @click="deleteService(props.row)"
                class="flex items-center ml-2 float-right bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
              >
                <svg
                  class="stroke-current h-5 w-5 mr-2"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6"
                  />
                </svg>
                <span></span>
              </button>
            </is-authorized>
            <is-authorized
              :permissions="['manage:fleets', 'update:fleets', 'read:fleets']"
            >
              <button
                type="button"
                @click="handleEditService(props)"
                class="float-right flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
              >
                <svg
                  class="stroke-current h-5 w-5 mr-2"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                  />
                </svg>
                <span></span>
              </button>
            </is-authorized>
          </span>
          <span v-else>{{ props.formattedRow[props.column.field] }}</span>
        </template>
      </vue-good-table>
      <p class="mx-auto my-16" v-if="services.length == 0">
        There are no Services for this Fleet.
      </p>
    </div>

    <portal to="overlay-outlet">
      <panel
        :showing="addEditServicePanelOpen"
        @close="addEditServicePanelOpen = false"
        :title="editingService.service_id ? 'Edit Service' : 'Add Service'"
      >
        <AddEditService
          :service="editingService"
          @complete="saveService"
          @delete="deleteService"
        />
      </panel>
    </portal>

    <portal to="overlay-outlet">
      <confirm-alert
        :showingConfirm="confirmAlert.showing"
        :message="confirmAlert.message"
        @complete="confirmAlert.resultFunction"
      />
    </portal>
  </div>
</template>

<script>
const Notification = () => import("@/components/Notification.vue");
const ConfirmAlert = () => import("@/components/ConfirmAlert.vue");
const Panel = () => import("@/components/Panel.vue");
const AddEditService = () =>
  import("@/components/Fleets/Edit/AddEditService.vue");
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

export default {
  name: "Service",
  components: {
    Notification,
    ConfirmAlert,
    Panel,
    AddEditService,
    IsAuthorized,
  },
  props: {
    services: Array,
  },
  data() {
    return {
      addEditServicePanelOpen: false,
      editingService: {},
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: "",
      },
      columns: [
        {
          label: "Type",
          field: "type",
          sortable: false,
          width: "15%",
        },
        {
          label: "Charge",
          field: "charge",
          sortable: false,
          width: "15%",
        },
        {
          label: "Date of Service",
          field: "service_date",
          // type: "date",
          formatFn: (v) => {
            return v == 0 ? "" : this.$moment(v).format("DD/MM/YYYY");
          },
          sortable: false,
          width: "20%",
        },

        {
          label: "Expiry Date",
          field: "expiry_date",
          // type: "date",
          formatFn: (v) => {
            return v == 0 ? "" : this.$moment(v).format("DD/MM/YYYY");
          },
          sortable: false,
          width: "20%",
        },

        {
          label: "Mileage",
          field: "mileage",
          sortable: false,
          width: "15%",
        },

        {
          field: "actions",
          type: "custom",
          sortable: false,
          width: "15%",
        },
      ],
    };
  },
  computed: {
    fleet_id: function () {
      return this.$store.state.lastSelectedFleet;
    },
  },
  methods: {
    addService: function () {
      this.editingService = {};
      this.addEditServicePanelOpen = true;
    },
    handleEditService: function (selectedRow) {
      let serviceData = selectedRow.row;
      this.editService(serviceData);
      /*this.$router.push({
              name: "AdEditService",
              params: { service: serviceData },
            });*/
    },
    editService: function (service) {
      this.editingService = { ...service };
      this.addEditServicePanelOpen = true;
    },
    saveService: async function (service) {
      // Ensure service has mandatory fields filled out
      let msg;
      if (!service.type) {
        msg = "Please provide service type.";
      } else if (!service.recurring_period) {
        msg = "Please provide the recurring period";
      } else if (!service.reminder_date) {
        msg = "Please provide service reminder date";
      }

      // this.services.forEach(function(item) {
      //   if (item.service_id != service.service_id) {
      //     if (service.recurring_date >= new Date() && new Date(item.recurring_date) > service.recurring_date) {
      //       msg = "Date overlap with other other services.";
      //     }
      //   }
      // });

      if (msg) {
        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "warning",
                  title: "Error",
                  close: onClose,
                },
              },
              msg,
            );
          },
          {
            position: "top-right",
          },
        );
      }

      this.addEditServicePanelOpen = false;

      try {
        service.is_active = true;
        if (service.recurring_date) {
          service.is_active = service.recurring_date >= new Date();
        }
        if (!service.service_id) {
          let createResult = await this.FleetService.createFleetService(
            this.fleet_id,
            service,
          );
          service.service_id = createResult.data.service_id;
          this.services.push(service);
        } else {
          await this.FleetService.updateFleetService(
            this.fleet_id,
            service.service_id,
            service,
          );
          let idx = this.$_.findIndex(
            this.services,
            (c) => c.service_id == service.service_id,
          );
          this.services.splice(idx, 1, service);
        }

        this.$emit("complete");

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  title: "Details Saved",
                  close: onClose,
                },
              },
              "Service details saved",
            );
          },
          {
            position: "top-right",
          },
        );
      } catch (err) {
        console.error(err);

        let msg = !service.service_id
          ? "There was a problem creating the new Service"
          : "There was a problem updating the Service";

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              "There was a problem updating the Service",
            );
          },
          {
            position: "top-right",
          },
        );
      }
    },
    handlePromoteServiceToCurrent: async function (result) {
      if (result) {
        this.confirmAlert.showing = false;
        try {
          this.confirmAlert.data.is_active = true;
          await this.saveService(this.confirmAlert.data);
          this.services.forEach((c) => {
            if (c.service_id !== this.confirmAlert.data.service_id) {
              c.is_active = false;
            }
          });
        } catch (error) {
          console.error(error);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Error",
                    close: onClose,
                  },
                },
                "There was a problem updating the Service",
              );
            },
            {
              position: "top-right",
            },
          );
        }
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    promoteServiceToCurrent: function (service) {
      this.confirmAlert = {
        resultFunction: this.handlePromoteServiceToCurrent,
        message: `Change '${service.name}' as current service?`,
        showing: true,
        data: service,
      };
    },
    handleDeleteService: async function (result) {
      if (result) {
        this.confirmAlert.showing = false;
        try {
          this.isBusy = true;
          await this.FleetService.deleteService(
            this.fleet_id,
            this.confirmAlert.data.service_id,
          );
          let idx = this.$_.findIndex(this.services, (c) => {
            return c.service_id == this.confirmAlert.data.service_id;
          });

          this.$delete(this.services, idx);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    title: "Service Deleted",
                    close: onClose,
                  },
                },
                "Successfully deleted service",
              );
            },
            {
              position: "top-right",
            },
          );
        } catch (err) {
          console.error(err);

          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Error",
                    close: onClose,
                  },
                },
                "There was a problem deleting the Service",
              );
            },
            {
              position: "top-right",
            },
          );
        }

        this.isBusy = false;
        this.addEditServicePanelOpen = false;
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    deleteService: function (service) {
      // If we're deleting a service that hasn't been saved yet
      if (String(service.service_id).startsWith("pending")) {
        let idx = this.$_.findIndex(this.services, (c) => {
          return c.service_id == service.service_id;
        });

        this.$delete(this.services, idx);
        return;
      }

      this.confirmAlert = {
        resultFunction: this.handleDeleteService,
        message: `Are you sure you wish to delete '${service.name}' This cannot be undone.`,
        showing: true,
        data: service,
      };
    },
  },
};
</script>
